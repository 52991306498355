import { NextPageContext } from 'next';
import { LngLat } from 'maplibre-gl';
import { apiCall } from '@/utils/api';
import { IAuditGNSS } from '@/types/admin';
import { GeoJSONFeature } from '@/types/geojson';

export const getGnssData = async (
  id: string,
  req?: NextPageContext['req'],
): Promise<IAuditGNSS> => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/auditgnss/${id}`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getLandmark = async (id: string, req?: NextPageContext['req']) => {
  try {
    const URL = `/admin/landmark/${id}`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching landmark info: ', error);
    return { error };
  }
};

export const getDetectionArea = async (id: string) => {
  try {
    const URL = `/admin/detectionarea/${id}`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching detection area info: ', error);
    return { error };
  }
};

export const getDetectionAreaAssets = async (id: string) => {
  try {
    const URL = `/admin/detectionarea/${id}/assets`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching detection area assets: ', error);
    return { error };
  }
};
export const getObjectDetection = async (
  id: string,
  req?: NextPageContext['req'],
) => {
  try {
    const URL = `/objectdetections/${id}`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching objectdetection info: ', error);
    return { error };
  }
};

export const getMapFeatureCandidate = async (
  id: string,
  req?: NextPageContext['req'],
) => {
  try {
    const URL = `/mapfeaturecandidates/${id}`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching mapfeaturecandidate info: ', error);
    return { error };
  }
};

export const getDetectionInfo = async (
  frameId: string,
  detectionId: number,
  req?: NextPageContext['req'],
) => {
  try {
    const URL = `/admin/frame/${frameId}/${detectionId}`;
    const res = await apiCall(`${process.env.NEXT_PUBLIC_API}${URL}`, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return res;
  } catch (error: any) {
    console.log('Error fetching detection info: ', error);
    return { error };
  }
};

export const getTrackedIntersectionInfo = async (
  id: string,
  req?: NextPageContext['req'],
) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/tracked-intersection/${id}`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getCoverageReports = async (
  geometry: GeoJSONFeature,
  req?: NextPageContext['req'],
) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/coveragereports`;
  try {
    const response = await apiCall(url, {
      method: 'POST',
      headers: {
        cookie: req?.headers.cookie,
      },
      body: JSON.stringify({ geometry }),
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const enqueueReconstruction = async (
  coverageReports: string[],
  req?: NextPageContext['req'],
) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/enqueueReconstruction`;
  try {
    const response = await apiCall(url, {
      method: 'POST',
      headers: {
        cookie: req?.headers.cookie,
      },
      body: JSON.stringify({ coverageReports }),
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getImageQualityTestPageConfig = async () => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/image-quality-test-page-config`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getPhiVisionExperimentConfigs = async (label: string) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/phi-vision-experiment/${label}`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getRegionConfigurationUpdateRecordForKeys = async (
  keys: string[],
) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/regionConfigurationUpdateRecord`;
  try {
    const response = await apiCall(url, {
      method: 'POST',
      body: JSON.stringify({ keys }),
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};

export const getClosestConnectorOrSegmentImagery = async ({
  lng,
  lat,
}: LngLat) => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/coverage/${lng},${lat}`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    return { error };
  }
};

export const getConstructionDataUrl = async () => {
  const url = `${process.env.NEXT_PUBLIC_API}/admin/getLatestConstructionEvalData`;
  try {
    const response = await apiCall(url, {
      method: 'GET',
    });
    return response;
  } catch (error: any) {
    console.log(error);
    return { error };
  }
};
